
import { Component, Mixins } from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { debounce } from "quasar";
import PreguntasDinamicas from "@/components/forms/PreguntasDinamicas.vue";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

interface ticketData {
  street: string;
  street_number: string;
  hint: string;
  message: string;
}

interface respuesta {
  id_pregunta: number | null;
  id_formulario: number | null;
  pregunta: string | null;
  respuesta: string | null;
  tipo: number | null;
  obligatorio: number | null;
}

interface dataRespuesta {
  id_formulario: number | null;
  preguntas: Array<respuesta>;
}

@Component({
  components: {
    FilePond,
    VueRecaptcha,
    PreguntasDinamicas
  }
})
export default class SendTicketForm extends Mixins(FormValidator) {
  razonSocial: any = null;
  tipo_usuario: number | null = null;

  rut_empresa = null;

  personaJuridicaValue = false;

  protected formDataEmpresa = {
    document_id: ""
  };

  protected formatRutEmpresa() {
    if (
      this.formDataEmpresa.document_id.replace("-", "").replace(/[.-]/g, "")
        .length <= 7
    ) {
      this.formDataEmpresa.document_id = this.formDataEmpresa.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      this.formDataEmpresa.document_id = this.formDataEmpresa.document_id
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }

  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  protected formData: ticketData = {
    street: "",
    street_number: "",
    hint: "",
    message: ""
  };

  options_street: any = [];
  street_loading = false;
  check_loading = false;
  street_search_empty = false;
  disable_button = false;
  streets: any[] = [];
  uploadProgressBar = false;
  uploadProgressPercent = 0;
  private requiereDocumento: boolean = false;
  private mensajeDocumento: string = "";
  private mostrarMensajeDocumento: boolean = false;
  private formulario: any = null;
  private respuestas: Array<dataRespuesta> = [];
  private data_preguntas: any = [];

  private requiredCheckboxJuridica = false;

  private preguntas: Array<any> = [
    {
      id: 1,
      id_formulario: 2,
      pregunta: "Ingrese el rol de la propiedad (opcional)",
      tipo: 0,
      obligatorio: 0,
      icon: "place",
      label: "Rol de la propiedad"
    },
    {
      id: 2,
      id_formulario: 2,
      pregunta: "Ingrese dirección de la propiedad (opcional)",
      tipo: 0,
      obligatorio: 0,
      icon: "place",
      label: "Dirección de la propiedad"
    },
    {
      id: 3,
      id_formulario: 2,
      pregunta:
        "Si cuenta con n° de expediente anterior, ingresar aquí (opcional)",
      tipo: 0,
      obligatorio: 0,
      icon: "place",
      label: "Dirección de la propiedad"
    }
  ];

  options_tipo_usuario: Array<any> = [
    { value: 1, label: "Persona Trabajadora" },
    { value: 2, label: "Entidad Empleadora" },
    { value: 3, label: "Estudiante" },
    { value: 4, label: "Dirigente Sindical" },
    // { value: 5, label: "Responsable del Hogar" },
    { value: 6, label: "Persona Jubilada" },
    { value: 7, label: "Sector Público" },
    { value: 8, label: "Otro Perfil" }
  ];

  id_formulario = 0;
  mensaje = "";

  private mensajeEntidadJuridica =
    "Si esta solicitud tiene relación con una empresa señale datos como el RUT empresa/Razón social, domicilio, etc";

  private mensajeAdjunto =
    "Si desea complementar información puede adjuntar documentos (PDF, JPG, DOC o similares)";

  private mensajeMessage =
    "Le solicitamos que nos entregue la mayor cantidad de información que nos ayude a dar una mejor respuesta a su requerimiento, como por ejemplo fechas, folios, nombre de oficina, RUT empresa, etc";

  private default_address: number = 0;
  private loading: boolean = false;

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private getServicioMeta() {
    this.$axios
      .get("servicio-metas", {
        params: {
          filter: {
            where: {
              and: [
                {
                  id_servicio: Number(this.$store.state.ticket.service.id)
                },
                {
                  meta_key: "reservation_message"
                }
              ]
            }
          }
        }
      })
      .then(response => {
        if (response.data.length == 0) this.mensaje = "";
        else this.mensaje = response.data[0].meta_value;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          case "msg":
            officeType = "application/vnd.ms-outlook";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  onVerify(response: any) {
    this.validateForm();
  }

  onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private validateForm() {
    (this.$refs.registerForm as any).validate().then((response: Boolean) => {
      if (response) this.sendTicketAttemp();
    });
  }

  private sendTicketAttemp() {
    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      adjuntos = null;
    }

    this.disable_button = true;
    let error_flag = false;
    let mensaje = "";
    const files = (this.$refs.pond as any).getFiles();

    if (files.length == 0) this.$q.loading.show();

    let captcha = (window as any).grecaptcha.getResponse();

    // if (this.personaJuridicaValue) {
    //   if (this.formDataEmpresa.document_id.length == 0) {
    //     mensaje = "Debe ingresar un rut de empresa.";
    //     error_flag = true;
    //   }
    //   if (this.razonSocial == null || this.razonSocial == "") {
    //     mensaje = "Debe ingresar una razón social.";
    //     error_flag = true;
    //   }
    // }

    if (captcha == "") {
      mensaje = this.$t("notification.check_captcha") as string;
      error_flag = true;
    } else if (this.formData.street.length == 0) {
      mensaje = "Debe ingresar la calle de la dirección";
      error_flag = true;
    } else if (this.formData.street_number.length == 0) {
      mensaje = "Debe ingresar el número de la dirección";
      error_flag = true;
    } else if (this.formData.message.length == 0) {
      mensaje = "Debe ingresar un mensaje";
      error_flag = true;
    } else if (
      this.requiereDocumento &&
      files.length === 0 &&
      this.$store.state.ticket.motive == 1
    ) {
      mensaje = "Se debe adjuntar el documento solicitado";
      error_flag = true;
    }
    if (error_flag) {
      this.$q.notify({
        message: mensaje,
        color: "principal",
        position: "top",
        timeout: 5000
      });
      this.$q.loading.hide();
      return (this.disable_button = false);
    }

    const formData = new FormData();
    let validFiles = true;
    for (let index = 0; index < files.length; index++) {
      if (files[index].fileSize < 1024 * 1024 * 51) {
        formData.append("my-file-" + index, files[index].file);
      } else {
        validFiles = false;
      }
    }
    if (!validFiles) {
      this.$q.notify({
        message:
          "Los archivos adjuntos no deben exceder el máximo permitido (50mb)",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      this.$q.loading.hide();
      return;
    }
    let flag = true;
    if (
      this.data_preguntas &&
      this.data_preguntas.length > 0 &&
      this.$store.state.ticket.motive == 1
    ) {
      for (let i = 0; i < this.data_preguntas.length; i++) {
        if (
          this.data_preguntas[i].obligatorio == 1 &&
          this.data_preguntas[i].respuesta === ""
        ) {
          flag = false;
        }
      }
    }

    if (files.length > 0) this.uploadProgressBar = true;

    var comuna_ticket = null;
    if (this.$idComuna == -1) {
      this.formData.street = this.$store.state.ticket.guest
        ? this.$store.state.ticket.guest_adress_street
        : this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.ticket.guest
        ? this.$store.state.ticket.guest_adress_number
        : this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.ticket.guest
        ? this.$store.state.ticket.guest_adress_hint
        : this.$store.state.user.adress.hint;
      comuna_ticket = this.$store.state.ticket.guest
        ? this.$store.state.ticket.guest_adress_district
        : this.$store.state.user.adress.district;
    }

    if (flag) {
      this.$axios
        .post("tickets/solicitud", formData, {
          params: {
            razon_social: this.razonSocial,
            rut_empresa: this.formDataEmpresa.document_id,
            tipo_usuario: this.tipo_usuario,
            id_servicio: this.$store.state.ticket.service.id,
            tipo: Number(this.$store.state.ticket.motive), //motivo de solicitud
            rut: this.$store.state.logged
              ? this.$store.state.user.rut
              : this.$store.state.ticket.guest_id,
            tipo_documento: this.$store.state.logged
              ? Number(this.$store.state.user.document_type)
              : Number(this.$store.state.ticket.guest_document_type),
            adjunto: adjuntos,
            mensaje: this.formData.message,
            direccion: {
              calle: this.formData.street,
              numero: this.formData.street_number,
              aclaratoria: this.formData.hint,
              ...(comuna_ticket && { id_comuna: comuna_ticket })
            },
            dispositivo: navigator.userAgent
          },
          headers: {
            ...(this.$store.state.user.auth_token && {
              Authorization: "Bearer " + this.$store.state.user.auth_token
            }),
            Captcha: captcha ? captcha : null
          },
          onUploadProgress: progressEvent => {
            this.uploadProgressPercent =
              progressEvent.loaded / progressEvent.total;
          }
        })
        .then(response => {
          this.enviarPreguntas(response.data.id_ticket);

          let ticket = {
            id: response.data.id_ticket,
            message: response.data.mensaje,
            date: response.data.fecha,
            step: 4,
            adjuntos: response.data.adjunto ? response.data.adjunto : null,
            calle: this.formData.street,
            numero: this.formData.street_number,
            aclaratoria: this.formData.hint,
            estado_ticket: response.data.estado_ticket,
            nombre_estado: response.data.nombre_estado,
            correlativo: response.data.correlativo,
            direccion_default: response.data.direccion_default
          };

          this.$store.dispatch("submitRequestFinished", ticket);
          this.$q.loading.hide();
          this.disable_button = false;
        })
        .catch(error => {
          let message = "Ha ocurrido un problema";
          switch (error.response.data.error.message) {
            case "upload_adjunto_error":
              message =
                "Ha ocurrido un problema con los archivos adjuntos. Intente nuevamente";
              break;
            case "empty_captcha":
            case "invalid_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
          }
          this.$q.notify({
            message: message,
            color: "principal",
            position: "top",
            timeout: 5000
          });
          this.$q.loading.hide();
          this.disable_button = false;
        })
        .finally(() => {
          this.uploadProgressBar = false;
          this.uploadProgressPercent = 0;
        });
    } else {
      this.$q.loading.hide();
      this.disable_button = false;
      this.$q.notify({
        message: "Rellene los campos obligatorios (*)",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }
  }

  private get canAutoFillAdress() {
    if (this.$store.state.logged) {
      if (this.$store.state.user.adress.district == this.comuna_cliente) {
        return true;
      }
    } else {
      if (
        this.$store.state.ticket.guest_adress_district == this.comuna_cliente
      ) {
        return true;
      }
    }
    return false;
  }

  private autoFillAdress() {
    if (this.$store.state.logged) {
      this.formData.street = this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.user.adress.hint;
    } else {
      this.formData.street = this.$store.state.ticket.guest_adress_street;
      this.formData.street_number = this.$store.state.ticket.guest_adress_number;
      this.formData.hint = this.$store.state.ticket.guest_adress_hint;
    }
    return false;
  }

  private async filterStreet(val: string, update: any, abort: any) {
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_street = this.streets;
      });
      return;
    }
    update(() => {
      const value = val.toLowerCase();
      this.options_street = (this.streets as any).filter((street: any) => {
        return street.nombre.toLowerCase().indexOf(value) > -1;
      });
    });
  }

  private abortFilterFn() {}

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "POST",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };

  private checkVerify() {
    this.check_loading = true;
    this.$q.loading.show();
    this.$axios
      .get("personas/checkVerified", {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$store.dispatch("setEmailVerified", response.data);
      })
      .catch(error => {
        switch (error.response.data.error.message) {
          case "auth/internal-error":
            this.$q.notify({
              message: "Ha ocurrido un error interno",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/invalid-uid":
            this.$q.notify({
              message: "Id inválida",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/missing-uid":
            this.$q.notify({
              message: "La operación no",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/operation-not-allowed":
            this.$q.notify({
              message: "Operación no permitida",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          default:
            this.$q.notify({
              message: "Error desconocido",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
        }
      })
      .finally(() => {
        this.check_loading = false;
        this.$q.loading.hide();
      });
  }

  private getDefaultAddress() {
    this.$axios.get("direccions/default").then(response => {
      this.formData.street = response.data.calle;
      this.formData.street_number = response.data.numero;
      this.formData.hint = response.data.aclaratoria;
    });
  }

  private streetDistrictData() {
    this.$axios
      .get("calles", {
        params: {
          filter: {
            where: { estado: 1 },
            fields: ["id", "id_comuna", "nombre", "estado"],
            order: "nombre ASC"
          }
        }
      })
      .then(response => {
        this.streets = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getFormulario() {
    this.$q.loading.show();
    this.$axios
      .get("servicios/" + Number(this.$store.state.ticket.service.id), {
        params: {
          filter: {
            fields: [
              "id",
              "direccion_default",
              "requiere_documento",
              "mensaje_documento"
            ],
            include: [
              {
                relation: "formulario",
                scope: {
                  fields: ["id", "id_servicio"],
                  include: [
                    {
                      relation: "formulario_pregunta",
                      scope: {
                        where: { estado: 1 },
                        order: ["prioridad ASC"],
                        fields: [
                          "id",
                          "id_formulario",
                          "pregunta",
                          "tipo",
                          "obligatorio",
                          "icon",
                          "label",
                          "prioridad",
                          "estado"
                        ],
                        include: [
                          {
                            relation: "formulario_pregunta_opcions",
                            scope: {
                              fields: ["id", "id_formulario_pregunta", "label"]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      })
      .then(response => {
        if (response.data.direccion_default == 1) {
          this.default_address = response.data.direccion_default;
          this.getDefaultAddress();
        }
        this.requiereDocumento =
          response.data.requiere_documento &&
          response.data.requiere_documento == 1
            ? true
            : false;
        this.mensajeDocumento =
          response.data.mensaje_documento &&
          response.data.mensaje_documento != ""
            ? response.data.mensaje_documento
            : "";
        if (response.data.formulario && response.data.formulario.length > 0) {
          for (let i = 0; i < response.data.formulario.length; i++) {
            const pregunta = response.data.formulario[i];
            if (pregunta.formulario_pregunta) {
              for (let j = 0; j < pregunta.formulario_pregunta.length; j++) {
                var tempRespuesta = "";
                if (pregunta.formulario_pregunta[j].tipo == 1) {
                  tempRespuesta = "No";
                } else {
                  tempRespuesta = "";
                }
                var opciones = [];
                if (
                  pregunta.formulario_pregunta[j].formulario_pregunta_opcions
                ) {
                  for (
                    let l = 0;
                    l <
                    pregunta.formulario_pregunta[j].formulario_pregunta_opcions
                      .length;
                    l++
                  ) {
                    opciones.push({
                      value:
                        pregunta.formulario_pregunta[j]
                          .formulario_pregunta_opcions[l].id,
                      label:
                        pregunta.formulario_pregunta[j]
                          .formulario_pregunta_opcions[l].label
                    });
                  }
                }
                this.data_preguntas.push({
                  id_pregunta: pregunta.formulario_pregunta[j].id,
                  id_formulario: pregunta.formulario_pregunta[j].id_formulario,
                  pregunta: pregunta.formulario_pregunta[j].pregunta,
                  respuesta: tempRespuesta,
                  opciones: opciones,
                  tipo: pregunta.formulario_pregunta[j].tipo,
                  obligatorio: pregunta.formulario_pregunta[j].obligatorio,
                  icon: pregunta.formulario_pregunta[j].icon,
                  label: pregunta.formulario_pregunta[j].label
                });
              }
            }
          }
        }
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private enviarPreguntas(idTicket: any) {
    let respuestas_formulario = this.data_preguntas.filter((item: any) => {
      return item.tipo != 5;
    });
    this.$axios
      .post("formularioRespuesta/createAll", {
        id_ticket: Number(idTicket),
        respuestas: respuestas_formulario
      })
      .then(response => {})
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un problema",
          color: "principal",
          position: "top",
          timeout: 5000
        });
        this.$q.loading.hide();
        this.disable_button = false;
      });
  }

  private mounted() {
    this.getFormulario();
    this.getServicioMeta();
    this.streetDistrictData();
    if (this.$store.state.logged) {
      this.checkVerify();
    }
  }

  private personaJuridicaFunction() {
    return this.personaJuridicaValue
      ? (this.requiredCheckboxJuridica = true)
      : (this.requiredCheckboxJuridica = false);
  }

  public isRutForm(value: string) {
    this.disable_button = false;
    let error_flag = false;
    let mensaje = "";

    var texto = value;
    if (texto.length > 12 || texto.length < 8) {
      error_flag = true;

      if (error_flag) {
        this.disable_button = true;
      } else {
        this.disable_button = false;
      }

      return "Ingrese un rut válido";
    }
    var check_letters = texto.match(/([A-Za-z])/g);
    if (check_letters && check_letters.length >= 2) {
      return "El rut no puede tener 2 letras";
    }
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }

    this.disable_button = true;

    return this.$t("validation.invalid_rut");
  }
}
